$(document).ready(function(){

    // Scroll animation menu
    $(window).scroll(function() {    
        var scroll = $(window).scrollTop();

        if (scroll >= 160) {
            $("li.menu-item>a").addClass("item-animation");
            $(".logo-casa-panque").addClass("min-logo");
        } else {
            $("li.menu-item>a").removeClass("item-animation");
            $(".logo-casa-panque").removeClass("min-logo");
        }
    });

    // Slick nav combine 2 menus
    var menuLeft = $('#nav-left').clone();
    var menuRight = $('#nav-right').clone();

    menuRight.children('li').appendTo(menuLeft);

    menuLeft.slicknav({
		prependTo:'#nav-mobile',
        closeOnClick:'true',
        label: '',
        closedSymbol: '&#9658;',
        openedSymbol: '&#9660;',
        brand:	'<img width="120" class="logo-casa-panque" src="https://casadepanque.com/wp-content/themes/CasaDePanqueTheme/assets/images/icons/logo-casadepanque.png" alt="">',		
    });  

    // Slider Galeria
    $('.slick-slider-galeria').slick({
        centerMode: true,
        centerPadding: '20px',
        slidesToShow: 1,
        adaptiveHeight: true,
        responsive: [{
            breakpoint: 768,
            settings: {
                arrows: true,
                centerMode: true,
                centerPadding: '10px',
                slidesToShow: 1
            }
        },{
            breakpoint: 480,
            settings: {
                arrows: true,
                centerMode: true,
                centerPadding: '10px',
                slidesToShow: 1
            }
        }]
    });

    // Slider testemunhos
    $('.slick-slider-testemunhos').slick({
        dots: true,
        autoplay: true,
        autoplaySpeed: 6000,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        adaptiveHeight: true,
        fade: true,
        cssEase: 'linear',
        touchMove: false,
        arrows: false
    });

    $('.without-caption').magnificPopup({
		type: 'image',
		closeOnContentClick: true,
		closeBtnInside: true,
		mainClass: 'mfp-no-margins mfp-with-zoom',
		image: {
			verticalFit: true
		},
		zoom: {
			enabled: true,
			duration: 300 
		},
        gallery: {
            enabled: false,
            preload: [0,2],
        } 
	});

    $("#shareIcons").jsSocials({
        showLabel: false,
        showCount: false,
        shares: ["twitter", "facebook", "googleplus", "pinterest"]
    });

// -------------------------------------------------
//                Booking System
// -------------------------------------------------

    // Condicional de verificação da data de inicio

    // var inicioReserva = new Date($('#booking-form').data('inicio-reservas'));

    // var dataAtual = new Date();

    // if ( dataAtual.getTime() > inicioReserva.getTime() ) {
    //      $('#booking-form').data('inicio-reservas', moment(dataAtual).format('YYYY-MM-DD').toString() );
    // }

    // Date Range Picker
    $('#two-inputs').dateRangePicker(
	{
        separator: ' a ',
        language: 'pt',
        stickyMonths: true,
        format: 'YYYY-MM-DD HH:mm',
        startOfWeek: 'monday',
        autoClose: true,
        //startDate: $('#booking-form').data('inicio-reservas'),
        //endDate: $('#booking-form').data('fim-reservas'),
        time: {
            enabled: true
        },
        defaultTime: moment().startOf('day').hours(16).minutes(0).seconds(0).milliseconds(0),
        defaultEndTime: moment().endOf('day').hours(10).minutes(0).seconds(0).milliseconds(0),
        getValue: function()
        {
            if ($('#date-checkin').val() && $('#date-checkout').val() )
                return $('#date-checkin').val() + ' a ' + $('#date-checkout').val();
            else
                return '';
        },
        setValue: function(s,s1,s2)
        {
            $('#date-checkin').val(s1);
            $('#date-checkout').val(s2);
            dateDifferencePrice();
        },
        //beforeShowDay: DisableSpecificDates, // data bloqueadas
    });

});

// Calculate Days
function dateDifferencePrice() {
    if($("#date-checkin").val()!='' && $("#date-checkout").val()!= '') {
        var oneDay = 24*60*60*1000;

        // Datas 
        var checkin = new Date($("#date-checkin").val());
        var checkout = new Date($("#date-checkout").val());

        // Cálculo do número de dias
        var diffDays = Math.round(Math.abs((checkin.getTime() - checkout.getTime())/(oneDay))); 
        //console.log('Nº Noites: ' + diffDays);

        // JSON detalhes de epocas
        var listEpocas = $('#booking-form').data('json-epocas');
    
        // Datas Bloqueadas
        var disableddates = $('#booking-form').data('bloqueadas');
        //console.log('Datas Bloqueadas: ' + disableddates);

        var precoTodal = 0;
        var precoInitial = 0;

        var erroVarNoites = 0;

        var erroMinDia = false;
        var erroOutEpoca = false;
        var erroOcupada = false;

        var dateArray = [];

        // loop do intervalo de dias de reserva
        while( checkin < checkout ) {

            dateArray = [];

            // loop de época
            for (var key in listEpocas) {

                var inicioEpoca = new Date(listEpocas[key].inicioEpoca).setHours(0, 0, 0, 0);
                var fimEpoca = new Date(listEpocas[key].fimEpoca).setHours(23, 59, 0, 0);

                // console.log('inicioEpoca: ' + inicioEpoca);
                // console.log('fimEpoca: ' + fimEpoca);

                var precoEpoca = parseFloat(listEpocas[key].precoEpoca);

                var minNoites = parseInt(listEpocas[key].minNoites);

                console.log('Data Checkin: ' + checkin);
                console.log('Preço Epoca: ' + precoEpoca);

                // Validação de condição de época
                if ( checkin >= inicioEpoca && checkin < fimEpoca ) {

                    console.log('Num Noites: ', minNoites);

                    // Validação de Minimo de dias 
                    if (diffDays < minNoites ) {
                        erroMinDia = true;
                        erroVarNoites = minNoites;
                    }

                    // Validação de Data destro de época
                    dateArray.push(true);
                    
                    // console.log('Passou na condição! Data: ' + checkin);
                    // console.log('Checkin: ' + checkin + ' < Checkout: ' + checkout);
                    // console.log('-----------------');

                    precoTodal += precoEpoca;

                } else {
                    dateArray.push(false);
                }

            } // End for

            //console.log('Array: ' + dateArray);

            //var dataInEpoca = dateArray.indexOf("true");

            if ( dateArray.indexOf(true) === -1 ) {
                    erroOutEpoca = true;
                    //console.log('TRUE encontrado: ');
            }

            // Verificar datas ocupadas
            for ( var i = 0; i < disableddates.length; i++ ) {
                
                var dataOcupada = new Date(disableddates[i]).setHours(1,0,0,0);
                var dataCompara = checkin.setHours(1,0,0,0);

                var dataControl = checkout.setHours(1,0,0,0);

                // converter data do array em DATE e comparar
                if ( dataCompara === dataOcupada ) {
                    erroOcupada = true;                  
                }
            }

            var newDate = checkin.setDate(checkin.getDate() + 1);
            checkin = new Date(newDate);

        } // End While

        if ( erroMinDia === true || erroOutEpoca === true || erroOcupada === true ) {
            $('input[type=submit]').attr('disabled','disabled');
        } else {
            $("input[type=submit]").removeAttr('disabled');
        }

        // Erros
        if ( erroMinDia === true ) {
            $('.cd-popup-min').addClass('is-visible');
            $(".min-error-var").empty().append(erroVarNoites);
            erroMinDia = false;
        } else {
            $('.cd-popup-min').removeClass('is-visible');
        }

        if ( erroOutEpoca === true ) {
            $('.cd-popup-out').addClass('is-visible');
            erroOutEpoca = false;
        } else {
            $('.cd-popup-out').removeClass('is-visible');
        }

        if ( erroOcupada === true ) {
            $('.cd-popup-ocupado').addClass('is-visible');
            erroOcupada = false;
        } else {
            $('.cd-popup-ocupado').removeClass('is-visible');
        }

        

        // Cálculo de pre-pagamento
        precoInitial = precoTodal * 0.5;

        $('#num-noites').val(diffDays);
        $('#valor-pagar').val(precoTodal.toFixed(2) + '€');
        $('#valor-initial').val(precoInitial.toFixed(2) + '€');
    }
}

// Sort Date
function sortDates(a, b) {
    return a.getTime() - b.getTime();
}

// END Booking System

(function($) {

/*
*  new_map
*
*  This function will render a Google Map onto the selected jQuery element
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	$el (jQuery element)
*  @return	n/a
*/

function new_map( $el ) {
	
	// var
	var $markers = $el.find('.marker');
	
	
	// vars
	var args = {
		zoom		: 16,
		center		: new google.maps.LatLng(0, 0),
		mapTypeId	: google.maps.MapTypeId.ROADMAP
	};
	
	
	// create map	        	
	var map = new google.maps.Map( $el[0], args);
	
	
	// add a markers reference
	map.markers = [];
	
	
	// add markers
	$markers.each(function(){
		
    	add_marker( $(this), map );
		
	});
	
	
	// center map
	center_map( map );
	
	
	// return
	return map;
	
}

/*
*  add_marker
*
*  This function will add a marker to the selected Google Map
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	$marker (jQuery element)
*  @param	map (Google Map object)
*  @return	n/a
*/

function add_marker( $marker, map ) {

	// var
	var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

	// create marker
	var marker = new google.maps.Marker({
		position	: latlng,
		map			: map
	});

	// add to array
	map.markers.push( marker );

	// if marker contains HTML, add it to an infoWindow
	if( $marker.html() )
	{
		// create info window
		var infowindow = new google.maps.InfoWindow({
			content		: $marker.html()
		});

		// show info window when marker is clicked
		google.maps.event.addListener(marker, 'click', function() {

			infowindow.open( map, marker );

		});
	}

}

/*
*  center_map
*
*  This function will center the map, showing all markers attached to this map
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	map (Google Map object)
*  @return	n/a
*/

function center_map( map ) {

	// vars
	var bounds = new google.maps.LatLngBounds();

	// loop through all markers and create bounds
	$.each( map.markers, function( i, marker ){

		var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

		bounds.extend( latlng );

	});

	// only 1 marker?
	if( map.markers.length == 1 )
	{
		// set center of map
	    map.setCenter( bounds.getCenter() );
	    map.setZoom( 16 );
	}
	else
	{
		// fit to bounds
		map.fitBounds( bounds );
	}

}

/*
*  document ready
*
*  This function will render each map when the document is ready (page has loaded)
*
*  @type	function
*  @date	8/11/2013
*  @since	5.0.0
*
*  @param	n/a
*  @return	n/a
*/
// global var
var map = null;

$(document).ready(function(){

	$('.acf-map').each(function(){

		// create map
		map = new_map( $(this) );

	});

});

})(jQuery);